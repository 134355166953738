@use "../config/" as *;
.waf-standings {
    .layout-wrapper {
        @extend %relative;
        .waf-head {
            .title {
                @extend %neutral-50;
            }
        }
    }
    .waf-footer {
        @extend %d-none;
    }
}
.standings-table {
    .layout-wrapper {
        @extend %p-0;
    }
    .table {
        @extend %neutral-50-bg;
        @extend %overflow;
        @extend %full-radius;
        .table-data {
            flex-shrink: 0;
        }
        .matches {
            &-position,
            &-team {
                @extend %sticky;
                @extend %neutral-50-bg;
                .table-data-wrap {
                    justify-content: flex-start;
                }
            }
            &-position {
                left: 0;
            }
            &-team {
                left: 7%;
            }
        }
        &-head {
            .table-data {
                @extend %secondary-900-bg;
                &:first-child {
                    border-top-left-radius: var(--space-1);
                }
                .form-guide {
                    border-top-right-radius: var(--space-1);
                }
            }
            .table-row {
                .matches {
                    &-position,
                    &-team {
                        @extend %secondary-900-bg;
                    }
                }
            }
            .text {
                white-space: nowrap;
            }
        }
        &-body {
            .table-data {
                @extend %neutral-50-bg;
                border-bottom: 0.1rem solid var(--secondary-200, 0.5);
            }
            .table-data-wrap {
                @extend %h-100;
                @extend %w-100;
                @extend %relative;
            }
            .position-indicator {
                height: 2rem;
                width: 2rem;
                border-bottom-right-radius: 66%;
                padding-top: 0.2rem;
                padding-right: 0.3rem;
                top: -0.1rem;
                left: -0.2rem;
                @extend %font-12-pm;
                @extend %neutral-50;
                @extend %secondary-800-bg;
                @extend %absolute;
                @extend %uppercase;
            }
            .team-image {
                @extend %neutral-100-bg-2;
            }
            .waf-accordion-head {
                .table-row {
                    flex-wrap: nowrap;
                    @extend %neutral-50-bg;
                }
            }
            .form-guide {
                &-listing {
                    padding: 0;
                    gap: var(--space-2);
                }
                &-item {
                    height: 2.2rem;
                    width: 2.2rem;
                }
            }
            .highlight {
                @extend %primary-500-bg;
                .table-data {
                    @extend %primary-500-bg;
                    @extend %font-14-pb;
                    &:first-child {
                        border-top-left-radius: var(--space-1);
                    }
                    &:last-child {
                        border-top-right-radius: var(--space-1);
                    }
                }
            }
        }
        .table-data {
            width: 12.5%;
            &.matches-nr {
                width: 18%;
            }
        }
        .matches-position {
            width: 7%;
            .position,
            .btn-accordion-trigger {
                @extend %d-none;
            }
        }
        .matches-team {
            width: 25%;
            @extend %flex-fs-c;
        }
        .form-guide {
            width: 50%;
            order: 9;
            justify-content: center;
        }
    }
}
@media screen and (max-width: 350px) {
    .standings-table {
        .table {
            .matches-team {
                width: 28%;
            }
            .form-guide {
                width: 47%;
            }
            &-body {
                .form-guide-listing {
                    gap: var(--space-1);
                }
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .standings-table {
        .short-name {
            display: none;
        }
        .full-name {
            display: flex;
        }
        .table {
            .matches-team {
                justify-content: flex-start;
                .table-data-wrap {
                    justify-content: flex-start;
                }
            }
            .matches {
                &-position,
                &-team {
                    position: unset;
                }
            }
            .table-data {
                width: 6%;
                &.matches-nr {
                    width: 6%;
                }
            }
            .matches-position {
                width: 2.5%;
            }
            .matches-team {
                width: 42%;
            }
            .form-guide {
                width: 19.5%;
                justify-content: center;
            }
        }
        .team-image {
            --img-height: 4rem;
            --img-width: 4rem;
        }
    }
}